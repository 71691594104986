export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createHighChartOptions(stats: any[], name: string, valueMapper: (a: any) => number, isMobile: boolean) {
    return {
        legend: { enabled: false },
        title: { text: null },
        xAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0,
        },
        chart: {
            margin: [5, 0, 0, 0],
            spacing: [0, 0, 0, 0],
        },
        yAxis: {
            title: { text: null },
            tickAmount: 0,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
        },
        credits: { enabled: false },
        tooltip: {
            enabled: !isMobile,
            hideDelay: 0,
            outside: true,
            // @ts-ignore  see docs on https://api.highcharts.com/highcharts/tooltip
            positioner: function (w, h, p) {
                return { x: p.plotX - w / 2, y: p.plotY + 18 };
            },
            formatter: function () {
                // @ts-ignore
                const dateValue = new Date(stats[this.point.index].date);
                const monthString: string = capitalizeFirstLetter(
                    dateValue.toLocaleString('no-nb', {
                        month: 'long',
                        year: 'numeric',
                    }),
                );
                // @ts-ignore
                const numberString: string = new Intl.NumberFormat('fr-FR').format(Math.abs(this.y));
                return `<div style="font-size: 12px;line-height: 16px;"><b>${monthString}</b><br>${name}: ${numberString}</div>`;
            },
        },
        series: [
            {
                enableMouseTracking: !isMobile,
                color: '#2781D8',
                fillColor: '#ECF0FB',
                type: 'areaspline',
                name: name,
                marker: {
                    enabled: false,
                },
                data: stats.map((d) => [new Date(d.date), valueMapper(d)]),
            },
        ],
    };
}
