import { useCallback, useEffect, useState } from 'react';
import { Event, MessageBus } from '@podium/browser';
import { ContextStats } from '../fetchContextStats';
import { useContextStats } from './useContextStats';

const messageBus = new MessageBus();

type FilterState =
    | { state: 'initial' }
    | { state: 'pending' }
    | { state: 'success'; contextStats: ContextStats }
    | { state: 'error'; errorMessage: string };

export function useFilterUpdated(apiUrl: string) {
    const [filterState, setFilterState] = useState<FilterState>({
        state: 'pending',
    });
    const [searchParams, setSearchParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));
    const { contextStats, isError, isLoading } = useContextStats(apiUrl, searchParams);

    const callback = useCallback((event: Event) => {
        const params = createQueryString(event.payload as object);
        setSearchParams(params);
    }, []);

    useEffect(() => {
        if (isLoading) {
            setFilterState({ state: 'pending' });
        } else if (isError) {
            setFilterState({ state: 'error', errorMessage: isError });
        } else if (contextStats) {
            setFilterState({ state: 'success', contextStats });
        }
    }, [contextStats, isError, isLoading]);

    useEffect(() => {
        messageBus.subscribe('filter', 'updated', callback);
        return () => {
            messageBus.unsubscribe('filter', 'updated', callback);
        };
    }, [callback]);

    return filterState;
}

function createQueryString(queryParams: object): URLSearchParams {
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => searchParams.append(key, item));
        } else {
            searchParams.append(key, value);
        }
    });
    return searchParams;
}
