import { KrLogo } from './logos/kr';
import { SquareMeterChart } from './SquareMeterChart';
import React from 'react';
import { ContextStats } from './fetchContextStats';
import { Card } from '@warp-ds/react';
import InfoIcon from './logos/info';

interface SquareMeterProps {
    contextStats: ContextStats;
    isMobile: boolean;
    redirectUrl: string;
    setShowModal: (open: boolean) => void;
}

export const SquareMeterCard = ({ contextStats, isMobile, redirectUrl, setShowModal }: SquareMeterProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '0px',
            }}
        >
            <Card
                className="wfull podletcard"
                onClick={() => {
                    window.location.href = redirectUrl;
                }}
            >
                <div
                    style={{
                        height: '94px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        padding: '14px 14px 0px',
                    }}
                >
                    <div className="cardHeadText">Pris per kvadratmeter (m²)</div>
                    <button
                        type="button"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setShowModal(true);
                        }}
                        style={{
                            height: '34px',
                            width: '34px',
                            right: '1px',
                            top: '1px',
                            backgroundColor: 'white',
                            padding: '4.5px',
                        }}
                        className={`focus:border-aqua-300 hover:border-aqua-300 absolute inline hidden cursor-pointer rounded-full border border-transparent text-center align-top text-gray-500 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none md:block`}
                    >
                        <InfoIcon></InfoIcon>
                    </button>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                            marginTop: '8px',
                        }}
                    >
                        <KrLogo />
                        <div style={{ marginLeft: '8px', fontSize: '22px' }}>
                            {new Intl.NumberFormat('fr-FR').format(contextStats.data.squareMeterPriceAvgLast30Days)} kr
                        </div>
                    </div>
                    <div>i snitt per annonse siste 30 dager</div>
                </div>
                <div>
                    <SquareMeterChart stats={contextStats.data.squareMeterPriceHist} isMobile={isMobile} />
                </div>
            </Card>
        </div>
    );
};
