import { createElement } from 'react'
import { hydrate } from 'react-dom'
import { App } from './src/App'

const shadowRoot =
    document.querySelector('search-in-context-podlet-isolated')?.shadowRoot;

const getElement = (id) => shadowRoot?.getElementById(id)
const el = getElement('search-in-context-podlet');
const configText = getElement('search-in-context-podlet-config')?.textContent;
const config = configText ? JSON.parse(configText) : undefined;

if (el && config) {
    hydrate(createElement(App, config), el)
}
