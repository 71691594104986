import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ClickHistItem } from './fetchContextStats';
import { createHighChartOptions } from './utils';

interface AdClicksChartProps {
    stats: ClickHistItem[];
    isMobile: boolean;
}

export const AdClicksChart: React.FC<AdClicksChartProps> = ({ stats, isMobile }: AdClicksChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const options = createHighChartOptions(stats, 'Snittklikk', (a: ClickHistItem) => a.clicks, isMobile);
    return (
        <>
            <HighchartsReact
                containerProps={{ style: { width: '100%', height: '57px' } }}
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </>
    );
};
