import React, { useEffect } from 'react';
import CloseIcon from './logos/close';

interface ModalProps {
    show: boolean;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ show, onClose }) => {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]);

    if (!show) {
        return null;
    }

    return (
        <div className={'modalOverlay'} onClick={onClose}>
            <div className={'modalContent'} onClick={(e) => e.stopPropagation()}>
                <button className={'closeButton'} onClick={onClose}>
                    <CloseIcon />
                </button>
                <div className="px-16 py-16">
                    <span className="text-16 font-bold">{'Kvadratmeterpris'}</span>
                    <p className="text-14 mt-8 text-gray-700">
                        {
                            'For å finne gjennomsnittlig kvadratmeterpris for boliger i det aktuelle området tar vi utgangspunkt i prisantydning (inkludert eventuell fellesgjeld) for valgte boligtyper. Deretter beregner vi et gjennomsnitt basert på boligenes primærrom. Vær obs på at kvadratmeterpris basert på prisantydning ikke nødvendigvis reflekterer prisene som boligene faktisk selges for.'
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};
export default Modal;
