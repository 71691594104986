function Cursor(): JSX.Element {
    return (
        <div>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M28.5861 17.7447L12.0879 12.0869L17.7447 28.586L20.5732 24.3434L26.7014 30.4716C26.889 30.6592 27.1433 30.7645 27.4085 30.7645C27.6737 30.7645 27.9281 30.6592 28.1156 30.4716L30.4727 28.1146C30.6602 27.9271 30.7656 27.6727 30.7656 27.4075C30.7656 27.1423 30.6602 26.8879 30.4727 26.7004L24.3444 20.5721L28.5861 17.7447Z"
                    stroke="#71717A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.2512 22.6667C9.20895 22.5205 7.25175 21.7903 5.61275 20.5632C3.97374 19.3361 2.722 17.6637 2.00662 15.7453C1.29124 13.8268 1.14238 11.7432 1.57776 9.74252C2.01314 7.74185 3.01442 5.90851 4.46234 4.46084C5.91025 3.01318 7.74377 2.01222 9.74449 1.57719C11.7452 1.14216 13.8288 1.29139 15.7471 2.00712C17.6654 2.72284 19.3376 3.97489 20.5644 5.61413C21.7852 7.24531 22.5138 9.19148 22.6646 11.2229"
                    stroke="#71717A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M14.6668 5.76066C13.4032 5.30673 12.0308 5.21208 10.7129 5.48799C9.39503 5.7639 8.1873 6.39874 7.23356 7.31688C6.27982 8.23503 5.62028 9.39779 5.3335 10.6667"
                    stroke="#71717A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    );
}

export default Cursor;
