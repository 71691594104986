import useSWR from 'swr';

export function useContextStats(apiUrl: string, searchParams: URLSearchParams) {
    const fetcher = async (url: string) => {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Server responded with status ${response.status}`);

        const data = await response.json();
        if (data.message !== 'OK') throw new Error('Failed fetching from the server');

        return data;
    };
    const { data, error } = useSWR(`${apiUrl}?${searchParams.toString()}`, fetcher);

    return {
        contextStats: data,
        isError: error ? error.message : null,
        isLoading: !error && !data,
    };
}
