import { ContextStats } from './fetchContextStats';
import { AdsPublishedChart } from './AdPublishedChart';
import { Card } from '@warp-ds/react';
import NewAds from './logos/newAds';

export default function AdsPublishedCard({
    contextStats,
    isMobile,
    redirectUrl,
}: {
    contextStats: ContextStats;
    isMobile: boolean;
    redirectUrl: string;
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0px',
            }}
        >
            <Card
                className="wfull podletcard"
                onClick={() => {
                    window.location.href = redirectUrl;
                }}
            >
                <div
                    style={{
                        height: '94px',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '14px 14px 0px',
                        backgroundColor: 'white',
                    }}
                >
                    <div className="cardHeadText">Nye annonser</div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                            marginTop: '8px',
                        }}
                    >
                        <NewAds />
                        <div style={{ marginLeft: '8px', fontSize: '22px' }}>
                            {new Intl.NumberFormat('fr-FR').format(contextStats.data.adsPublishedLast30Days)}
                        </div>
                    </div>
                    <div>nye annonser siste 30 dager</div>
                    <div style={{ marginTop: '16px' }}>&nbsp;</div>
                </div>
                <div style={{ width: '100%' }}>
                    <AdsPublishedChart stats={contextStats.data.adsPublishedHist} isMobile={isMobile} />
                </div>
            </Card>
        </div>
    );
}
