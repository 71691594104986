import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AdsPublishedHistItem, ClickHistItem } from './fetchContextStats';
import { createHighChartOptions } from './utils';

interface AdsPublishedChartProps {
    stats: AdsPublishedHistItem[];
    isMobile: boolean;
}

export const AdsPublishedChart: React.FC<AdsPublishedChartProps> = ({ stats, isMobile }: AdsPublishedChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const options = createHighChartOptions(
        stats,
        'Nye annonser',
        (a: AdsPublishedHistItem) => a.publishedAds,
        isMobile,
    );

    // const options = createHighChartOptions(stats)
    return (
        <>
            <HighchartsReact
                containerProps={{ style: { width: '100%', height: '57px' } }}
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </>
    );
};
