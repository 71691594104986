import * as React from 'react';

export const KrLogo: React.FC = () => (
    <>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 1C7.71573 0.999999 0.999998 7.71573 0.999997 16C0.999996 24.2843 7.71572 31 16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1Z"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M8 10.6667L8 21.3333"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.3333 10.6667L8 15.5L13.3333 21.3333"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.3335 10.6667L20.0002 10.6667C21.4729 10.6667 22.6668 11.8606 22.6668 13.3333C22.6668 14.8061 21.4729 16 20.0002 16H17.3335"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.3335 10.6667L17.3335 21.3333"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.3335 16L22.6668 21.3333"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </>
);
