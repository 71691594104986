function ChevronDown(): JSX.Element {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.5 5.5L8 11L13.5 5.5"
                    stroke="#71717A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    );
}

export default ChevronDown;
