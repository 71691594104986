import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PushNotificationHistItem } from './fetchContextStats';
import { createHighChartOptions } from './utils';

interface NotificationsChartProps {
    stats: PushNotificationHistItem[];
    isMobile: boolean;
}

export const NotificationsChart: React.FC<NotificationsChartProps> = ({ stats, isMobile }: NotificationsChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const options = createHighChartOptions(stats, 'Varsler sendt', (a: PushNotificationHistItem) => a.push, isMobile);

    return (
        <>
            <HighchartsReact
                containerProps={{ style: { width: '100%', height: '57px' } }}
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </>
    );
};
