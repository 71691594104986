import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SquareMeterPriceItem } from './fetchContextStats';
import { createHighChartOptions } from './utils';

interface SquareMeterChartProps {
    stats: SquareMeterPriceItem[];
    isMobile: boolean;
}

export const SquareMeterChart: React.FC<SquareMeterChartProps> = ({ stats, isMobile }: SquareMeterChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const options = createHighChartOptions(
        stats,
        'Snittpris',
        (a: SquareMeterPriceItem) => a.avgSquareMeterPrice,
        isMobile,
    );

    return (
        <>
            <HighchartsReact
                containerProps={{ style: { width: '100%', height: '57px' } }}
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </>
    );
};
