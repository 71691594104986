import { ContextStats } from './fetchContextStats';
import { AdClicksChart } from './AdClicksChart';
import { Card } from '@warp-ds/react';
import Cursor from './logos/cursor';

export default function AdClicksCard({
    contextStats,
    isMobile,
    redirectUrl,
}: {
    contextStats: ContextStats;
    isMobile: boolean;
    redirectUrl: string;
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '0px',
            }}
        >
            <Card
                className="wfull podletcard"
                onClick={() => {
                    window.location.href = redirectUrl;
                }}
            >
                <div
                    style={{
                        height: '94px',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '14px 14px 0px',
                        backgroundColor: 'white',
                    }}
                >
                    <div className="cardHeadText">Klikk per annonse (første 8 dager)</div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                            marginTop: '8px',
                        }}
                    >
                        <Cursor />
                        <div style={{ marginLeft: '8px', fontSize: '22px' }}>
                            {new Intl.NumberFormat('fr-FR').format(
                                Math.abs(contextStats.data.clicksFirst8DaysAvgLast30Days),
                            )}{' '}
                            klikk
                        </div>
                    </div>
                    <div style={{ fontWeight: '300' }}>i snitt per annonse siste 30 dager</div>
                </div>
                <div>
                    <AdClicksChart stats={contextStats.data.clicksFirst8DaysHist} isMobile={isMobile} />
                </div>
            </Card>
        </div>
    );
}
