export default function DotNavigation({
    showDots,
    index,
    totalCount,
    navigateTo,
}: {
    showDots: boolean;
    index: number;
    totalCount: number;
    navigateTo: (index: number) => void;
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <div style={{ display: showDots ? '' : 'none' }}>
                <Dots count={totalCount} selected={index} onSelect={navigateTo} />
            </div>
        </div>
    );
}

function Dots({ count, selected, onSelect }: { count: number; selected: number; onSelect: (index: number) => void }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {[...Array(count)].map((_, i) => {
                const bgColor = selected == i ? '#0063FB' : '#C3CCD9';
                return (
                    <div
                        onClick={() => onSelect(i)}
                        style={{
                            marginLeft: '4px',
                            marginRight: '4px',
                            height: '8px',
                            width: '8px',
                            borderRadius: '4px',
                            backgroundColor: bgColor,
                            color: bgColor,
                        }}
                        key={i}
                    ></div>
                );
            })}
        </div>
    );
}
