import React from 'react';
import { ContextStats } from './fetchContextStats';
import { Card } from '@warp-ds/react';
import { NotificationsChart } from './NotificationChart';
import { NotificationsLogo } from './logos/notifications';

interface NotificationsCardProps {
    contextStats: ContextStats;
    isMobile: boolean;
    redirectUrl: string;
}

export const NotificationsCard = ({ contextStats, isMobile, redirectUrl }: NotificationsCardProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '0px',
            }}
        >
            <Card
                className="wfull podletcard"
                onClick={() => {
                    window.location.href = redirectUrl;
                }}
            >
                <div
                    style={{
                        height: '94px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        padding: '14px 14px 0px',
                    }}
                >
                    <div className="cardHeadText">Varsler sendt per annonse</div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                            marginTop: '8px',
                        }}
                    >
                        <NotificationsLogo />
                        <div style={{ marginLeft: '8px', fontSize: '22px' }}>
                            {new Intl.NumberFormat('fr-FR').format(contextStats.data.notificationAverageLast30Days)}{' '}
                            varslinger
                        </div>
                    </div>
                    <div>i snitt per annonse siste 30 dager</div>
                </div>
                <div>
                    <NotificationsChart stats={contextStats.data.notificationsAvgHist} isMobile={isMobile} />
                </div>
            </Card>
        </div>
    );
};
