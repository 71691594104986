import * as React from 'react';

export const NotificationsLogo: React.FC = () => (
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0098 21.7822C10.2782 22.6437 11.0982 23.2493 12.0025 23.2493C12.9069 23.2493 13.7269 22.6437 13.9953 21.7822"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M12 3V0.75" stroke="#71717A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M12 3C16.1682 3 19.5 6.49157 19.5 10.619C19.5 17.7769 21 19 21 19H3C3 19 4.5 17.0536 4.5 10.619C4.5 6.51979 7.86509 3 12 3Z"
                stroke="#71717A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </>
);
