import React, { ReactNode } from 'react';
import { useFilterUpdated } from './hooks/useFilterUpdated';
import { SearchStatsItem } from './SearchStatsItem';

export interface AppProps {
    apiUrl: string;
}

export const App = ({ apiUrl }: AppProps): ReactNode => {
    const filterState = useFilterUpdated(apiUrl);
    switch (filterState.state) {
        case 'initial':
            return <div style={{ display: 'none' }}>initial context stats</div>;
        case 'success':
            return <SearchStatsItem contextStats={filterState.contextStats} />;
        default:
            return null;
    }
};

export default App;
