function InfoIcon(): JSX.Element {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="1.5" />
                <path d="M12 16V12" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="12" cy="8" r="1" fill="black" />
            </svg>
        </div>
    );
}

export default InfoIcon;
